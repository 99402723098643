import React from "react";
import {
    Typography,
    Box,
    makeStyles,
} from "@material-ui/core";
import logo from '../../images/worknest-small.svg';

const useStyles = makeStyles(() => ({
    question: {
        padding: '15px',
        marginTop: '25px',
        marginLeft: '25px',
        marginRight: '25px',
        backgroundColor: '#f2edff',
        borderRadius: '5px',
        position: 'relative'
    },
    logo: {
        position: 'absolute',
        top: '-15px',
        left: '-15px',
        width: '30px',
        height: '30px',
        borderRadius: '50%'
    },
}));

const SectionHeader = ({ text }) => {
    const classes = useStyles();    

    return (
        <Box className={classes.question}>
            <Typography variant="subtitle2">{text}</Typography>
            <img className={classes.logo} src={logo} alt="logo"></img>
        </Box>
    );
};

export default SectionHeader;
