import React, { useState, useEffect } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import axios from "../../plugins/axios";

import {
    TextField
} from "@material-ui/core";

const apiUrl = process.env.REACT_APP_WORKNEST_TOOLS_API_URL;

const AccountLookup = ({ setAnswer, value, setText, text }) => {

    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [input, setInput] = useState();
    const [loading, setLoading] = useState(false);
    const [val, setVal] = useState({});

    useEffect(() => {
        let active = true;

        if (!input || input.length < 3) {
            setOptions([]);
            return undefined;
        }

        (async () => {
            setLoading(true);
            const clients = (await axios.get(`${apiUrl}/getclients/` + encodeURIComponent(input))).data;
            if (active) {
                setOptions(clients);
            }
            setLoading(false);
        })();

        return () => {
            active = false;
        };
    }, [open, input]);
    
    const onChange = (e, v) => {
        setText(v?.text)
        setAnswer(v?.value);
        setVal(v);   
    }

    const getOptionLabel = (option) => {
        return option.text || text || "";
    }
    
    return (
        <Autocomplete
            size="small"
            freeSolo
            value={val}
            open={open}
            onOpen={() => !value && setOpen(true)}
            onClose={() => setOpen(false)}
            getOptionSelected={(option, value) => option.value === value.value}
            getOptionLabel={getOptionLabel}
            onChange={onChange}
            options={options}
            loading={loading}
            onInputChange={(e, val, reason) => reason === "input" && setInput(val)}
            renderInput={(params) => {
                params.inputProps.autoComplete = "new-password";
                params.inputProps.readOnly = !!value;
                return <TextField variant="outlined" {...params} fullWidth />;
            }}
        />
    );
}

export default AccountLookup;
