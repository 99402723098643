import React, { useState, useEffect } from "react";
import axios from "../../plugins/axios";

import {
    FormControl,
    Select,
    MenuItem
} from "@material-ui/core";

const apiUrl = process.env.REACT_APP_WORKNEST_TOOLS_API_URL;

const ContactLookup = ({ setAnswer, value, accountId }) => {

    const [contacts, setContacts] = useState();   

    useEffect(() => {

        if (!accountId)
            return;

        const fetchContacts = async () => {
            let response = await axios.get(`${apiUrl}/getContacts/${accountId}`)
            setContacts(response.data);
        }

        fetchContacts();
    }, [accountId]);

    if (!accountId)
        return null;

    if (!contacts)
        return "searching ...";

    if (contacts.length === 0)
        return "no known contacts";

    return (
        <FormControl size="small" fullWidth>
            <Select
                variant="outlined"
                displayEmpty
                value={value}
                onChange={e => setAnswer(e.target.value)}
            >
                <MenuItem value="">select ...</MenuItem>
                {contacts.map(c => <MenuItem key={c.id} value={c.id}>{c.name}</MenuItem>)}                
            </Select>            
        </FormControl>
    );
}


export default ContactLookup;
