import React from "react";
import {
    TextField,
    Select,
    MenuItem,
    FormControl,
    Typography,
} from "@material-ui/core";
import { useSelector, useDispatch } from 'react-redux'
import { setAnswer } from '../../store/slices/leadFormSlice';

import RadioAnswer from "./RadioAnswer";
import ButtonAnswer from "./ButtonAnswer";
import AccountLookup from "./AccountLookup";
import ContactLookup from "./ContactLookup";
import TileGroup from "./TileGroup";

const LeadFormQuestion = ({ question }) => {

    const { answers } = useSelector((state) => state.leadFormReducer);

    const dispatch = useDispatch();

    const setAnswer2 = (key, answer) => {
        dispatch(setAnswer({ key, answer }));
    }

    const answer = answers[question.key] || "";    

    return (
        <div>
            {question.text && <Typography variant="subtitle2">{question.text}</Typography> }            
            {question.type === "email" &&
                <TextField
                    variant="outlined"
                    fullWidth
                    inputProps={{ maxLength: 80 }}
                    value={answer}
                    size="small"
                    onChange={(e) => setAnswer2(question.key, e.target.value)}
                />
            }
            {question.type === "account" &&
                <AccountLookup setAnswer={(a) => setAnswer2(question.key, a)} value={answer} text={answers["AccountAuto"]} setText={(a) => setAnswer("AccountAuto", a)} />            
            }
            {question.type === "contact" && answers.AccountId &&
                <ContactLookup setAnswer={(a) => setAnswer2(question.key, a)} value={answer} accountId={answers.AccountId} />
            }
            {question.type === "text" &&
                <TextField
                    variant="outlined"
                    fullWidth
                    inputProps={{ maxLength: question.maxLength }}
                    value={answer}
                    size="small"
                    onChange={(e) => setAnswer2(question.key, e.target.value)}
                />
            }
            {question.type === "textarea" &&
                <TextField
                    variant="outlined"
                    fullWidth
                    multiline
                    minRows={4} 
                    value={answer}
                    size="small"
                    onChange={(e) => setAnswer2(question.key, e.target.value)}
                />
            }
            {question.type === "radio" &&
                <RadioAnswer
                    options={question.options}
                    answer={answer}
                    setAnswer={(a) => setAnswer2(question.key, a)}
                />
            }
            {question.type === "buttons" &&
                <ButtonAnswer
                    options={question.options}
                    answer={answer}
                    setAnswer={(a) => setAnswer2(question.key, a)}
                />
            }
            {question.type === "tiles" &&
                <TileGroup
                    options={question.options}
                    answer={answer}
                    setAnswer={(a) => setAnswer2(question.key, a)}
                />
            }
            {question.type === "select" &&
                <FormControl variant="outlined" size="small" fullWidth>
                    <Select
                        displayEmpty
                        value={answer}
                        onChange={(e) => setAnswer2(question.key, e.target.value)}
                    >
                        {question.options.map((o) => <MenuItem key={o} value={o}>{o}</MenuItem>)}
                    </Select>
                </FormControl>
            }
        </div>
    );
};

export default LeadFormQuestion;
