import React from "react";
import styled, { withTheme } from "styled-components";
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import {
    AppBar as MuiAppBar,
    Toolbar
} from "@material-ui/core";

import worknestLogo from '../images/myworknest-insignia.svg';

const AppBar = styled(MuiAppBar)`
  background: #f8f9fa;
  color: #12054d;
  box-shadow: ${props => props.theme.shadows[1]};
`;

const Header = () => {        

    return (
        <React.Fragment>
            <AppBar position="sticky" elevation={0}>
                <Toolbar>                    
                    <img alt="worknest logo" src={worknestLogo} style={{ height: '30px' }} />
                    <div style={{ flexGrow: 1 }}></div>
                    <div><strong>Need Help? Call:</strong> <span style={{ color: '#212529' }}>0345 226 8393</span></div>
                    <IconButton><NotificationsNoneIcon /></IconButton>
                    <IconButton><MenuIcon /></IconButton>
                </Toolbar>
            </AppBar>            
        </React.Fragment>
    );
}

export default withTheme(Header);
