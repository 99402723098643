import React, { useState, useMemo, useCallback, useEffect } from "react";
import axios from "../../plugins/axios";
import {
    Typography,
    Box,
    makeStyles,
    Container,
    Button,
    Paper,
    useMediaQuery,
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SectionHeader from "./SectionHeader";
import LeadFormQuestion from "./LeadFormQuestion";
import "../../vendor/perfect-scrollbar.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import { leadStages, initialStage, dmMoverStage, accountStage } from "./questions";
import { useTheme } from "@material-ui/core/styles";
import { useAuth } from "../../contexts/authContext";
import { fetchLeadProducts, setAnswer } from '../../store/slices/leadFormSlice';
import { useSelector, useDispatch } from 'react-redux';
import _ from "lodash";

const useStyles = makeStyles(() => ({
    purpleBack: {
        backgroundColor: '#5c45c7',
        color: 'white',
        textAlign: 'center',
        padding: '10px',
        borderRadius: '5px'
    },
    button: {
        backgroundColor: '#5c45c7',
        color: 'white',
        '&:hover': {
            backgroundColor: '#150950'
        }
    },
    stages: {
        position: 'absolute',
        top: 60,
        left: -220,
        width: '200px',
        '& div:first-child': {
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px'
        },
        '& div:last-child': {
            borderBottomLeftRadius: '5px',
            borderBottomRightRadius: '5px'
        },
        '& .selected': {
            backgroundColor: '#150950',
            position: 'relative',
            color: 'white',
            padding: '10px',
            '&:after':
            {
                position: 'absolute',
                backgroundColor: '#150950',
                width: '10px',
                height: '10px',
                right: 0,
                top: '50%',
                marginTop: '-5px',
                marginRight: '-5px',
                content: '""',
                transform: 'rotate(45deg)'
            }
        },
        '& .above': {
            backgroundColor: '#5c45c7',
            color: 'white',
            padding: '10px',
        },
        '& .below': {
            backgroundColor: 'white',
            color: '#150950',
            padding: '10px',
        }
    },
    mobileStage: {
        width: '40px',
        height: '40px',
        borderRadius: '5px',
        '&.selected': {
            backgroundColor: '#150950',
        },
        '&.above': {
            backgroundColor: '#5c45c7',
        },
        '&.below': {
            backgroundColor: 'white',
        }
    },
    answer: {
        marginLeft: '50px',
        marginTop: '10px'
    },
    perfScroll: {
        maxHeight: "calc(100vh - 250px)"
    },
    dashes:
    {
        flex: 1,
        borderTop: '1px dashed'
    }
}))

const apiUrl = process.env.REACT_APP_WORKNEST_TOOLS_API_URL;

const LeadForm = () => {
    const classes = useStyles();
    const { user } = useAuth();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.only("xs"));

    const { leadProducts, answers } = useSelector((state) => state.leadFormReducer);

    const dispatch = useDispatch();

    useEffect(() => {
        if (leadProducts.length === 0)
            dispatch(fetchLeadProducts());

        console.log({leadProducts});
    }, [dispatch, leadProducts]);

    const [stage, setStage] = useState(0);

    const [formState, setFormState] = useState("completing");   

    useEffect(() => {
        dispatch(setAnswer({ key: 'ReferrerEmail', answer: user.details.email}));
    }, [dispatch, user.details.email]);

    const stages = useMemo(() => {
        let stages = [initialStage, accountStage];

        if (answers.Initial === "DMMOVER")
            stages.push(dmMoverStage);

        if (answers.Initial === "NEW" || answers.Initial === "EXISTING")
            stages = stages.concat(leadStages);

        return stages;

    }, [answers.Initial]);    

    const activeStage = useMemo(() => {
        let s = stages[stage];
        if (s.text === "Services") {

            s = _.cloneDeep(s);

            if (answers.Level1) {
                let list = leadProducts.filter(p => p.level1 === answers.Level1);
                let options = [
                    ...new Set(list.map((p) => p.level2)),
                ];
                let levelSection = {
                    header: `Please select ${answers.Level1} service from the dropdown`,
                    questions: [
                        {
                            key: "Level2",
                            type: "select",
                            options: options
                        }
                    ]
                }

                if (answers.Level2 && options.includes(answers.Level2)) {
                    list = list.filter(p => p.level2 === answers.Level2);
                    console.log({list, leadProducts, level2: answers.Level2});
                    if (list.length > 1) {
                        let options = [
                            ...new Set(list.map((p) => p.level3)),
                        ];

                        levelSection.questions.push({
                            key: "Level3",
                            text: "select from dropdown",
                            type: "select",
                            options: options
                        });

                        if (answers.Level3 && options.includes(answers.Level3)) {
                            list = list.filter(p => p.level3 === answers.Level3);
                        }
                    }                    
                }

                s.sections.push(levelSection);

                if (list.length === 1) {
                    let product = list[0];

                    dispatch(setAnswer({key: "ProductId", answer: product.id}))
                    
                    s.sections.push({
                        header: product.name,
                        questions: [
                            ...product.questions,
                            {
                                key: "NOTES:Additional",
                                type: "textarea",
                                text: "Additional Information / Requirements"
                            }
                        ]
                    });
                }
            }

            console.log(s.sections.length);
        }        

        return s;

    }, [stages, stage, answers.Level1, answers.Level2, answers.Level3, leadProducts, dispatch]);

    const activeStageQuestions = useMemo(() => {

        let questionGroups = activeStage.sections.map(s => s.questions);
        let flattened = questionGroups.flat(1);
        
        return flattened

    }, [activeStage.sections]);   


    const sectionValid = () => {        

        let valid = true;
        activeStageQuestions.forEach(q => {
            let visible = (!q.dependsOnKey || answers[q.dependsOnKey] === q.dependsOnValue);

            let ok = !visible || !!answers[q.key];
            if (!ok)
                valid = false;
        });
        
        return valid;
    }

    const submit = async () => {

        setFormState("submitting");

        let sections = stages.map(s => s.sections).flat(1);
        let questions = [];

        for (let s of sections) {
            for (let q of s.questions) {
                if (!q.dependsOnKey || answers[q.dependsOnKey] === q.dependsOnValue)
                    questions.push({ key: q.key, text: q.text || s.header });
            }
        }

        try {
            await axios.post(`${apiUrl}/submitlead`, { answers, questions });
            setFormState("complete");
        } catch (err) {
            setFormState("completing");
        }
    }

    const showQuestion = useCallback(q => {
        if (!q.dependsOnKey) {
            return true;
        }

        let parent = activeStageQuestions.find(x => x.key === q.dependsOnKey);
      
        if (parent && (answers[parent.key] === q.dependsOnValue && showQuestion(parent))) {
            return true;
        }        

        return false;
    }, [activeStageQuestions, answers]);

    const section = (s) => {

        let questions = s.questions.filter(q => showQuestion(q));

        if (questions.length === 0)
            return null;

        return <React.Fragment key={s.header || ""}>
            <>
                {s.header && <SectionHeader text={s.header.replace("[[Level1]]", answers.Level1)} />}
                <Paper className={s.header && classes.answer} style={{ padding: '20px' }}>
                    <Box display="flex" flexDirection="column" gridGap="10px">
                        {questions.map(q =>
                            <LeadFormQuestion
                                key={q.key}
                                question={q}
                            />)
                        }
                    </Box>
                </Paper>
            </>
        </React.Fragment>
    }

    return (
        <Container maxWidth="sm">
            <Box display="flex" flexDirection="column" gridGap="10px" style={{ position: 'relative' }}>
                <div className={classes.purpleBack}>Stronger Together Lead Form</div>
                {formState === "submitting" && <h1>Submitting form</h1>}
                {formState === "complete" && <h1>Form submitted</h1>}
                {formState === "completing" &&
                    <>
                        {stage > 1 && (isMobile ? (
                            <Box display="flex" flexDirection="column" gridGap="5px">
                                <Box display="flex" alignItems="center" gridGap="5px">
                                    {stages.map((s, index) =>
                                        <React.Fragment key={index}>
                                            {index > 0 &&
                                                <Box
                                                    //onClick={() => setStage(index)}
                                                    className={`${classes.mobileStage} ${stage === index ? 'selected' : stage < index ? 'below' : 'above'}`}
                                                />
                                            }
                                            {index > 0 && index < 5 && <Box className={classes.dashes} />}
                                        </React.Fragment>
                                    )}
                                </Box>
                                <Typography variant="subtitle2" style={{ color: '#5c45c7' }}>{activeStage.text}</Typography>
                            </Box>
                        ) : (
                            <Box display="flex" className={classes.stages} flexDirection="column">
                                {stages.map((s, index) =>
                                    <React.Fragment key={index}>
                                        {s.text &&
                                            <Box
                                                onClick={() => setStage(index)}
                                                className={stage === index ? 'selected' : stage < index ? 'below' : 'above'}
                                                key={index}>
                                                {index - 1}. {s.text}
                                            </Box>
                                        }
                                    </React.Fragment>
                                )}
                            </Box>
                        ))}
                        <PerfectScrollbar className={classes.perfScroll}>
                            {activeStage.sections.map(s => section(s))}
                        </PerfectScrollbar>
                        <Box display="flex">
                            {stage > 0 && <Button onClick={() => setStage(stage - 1)} color="primary" className={classes.button} variant="contained"><ArrowBackIcon fontSize="small" /> Back</Button>}
                            <Box flexGrow={1}></Box>
                            {stage < stages.length - 1 && <Button disabled={!sectionValid()} onClick={() => setStage(stage + 1)} color="primary" className={classes.button} variant="contained">Next <ArrowForwardIcon fontSize="small" /></Button>}
                            {stage === stages.length - 1 && <Button disabled={!sectionValid()} onClick={submit} color="primary" className={classes.button} variant="contained">Submit</Button>}
                        </Box>
                    </>
                }
            </Box>
        </Container>
    );
};

export default LeadForm;
