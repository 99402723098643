import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from "../../plugins/axios";

const apiUrl = process.env.REACT_APP_WORKNEST_TOOLS_API_URL;

export const fetchLeadProducts = createAsyncThunk(
    'leadForm/fetchLeadProducts',
    async () => {
        const response = await axios.get(`${apiUrl}/leadproducts`);
        return response.data;
    },
);

export const leadFormSlice = createSlice({
    name: 'leadForm',
    initialState: {
        fetchingLeadProducts: false,
        leadProducts: [],
        answers: {}
    },
    reducers: {
        setAnswer(state, action) {
            state.answers = {
                ...state.answers,
                [action.payload.key]: action.payload.answer
            };
        }  
    },
    extraReducers: (builder) => {
        builder.addCase(fetchLeadProducts.pending, (state, action) => {
            state.fetchingLeadProducts = true;
        });

        builder.addCase(fetchLeadProducts.fulfilled, (state, action) => {
            state.leadProducts = action.payload;
            state.fetchingLeadProducts = false;
        });

        builder.addCase(fetchLeadProducts.rejected, (state, action) => {
            state.fetchingLeadProducts = false;
        });        
    }
})

export const { setAnswer } = leadFormSlice.actions;
export default leadFormSlice.reducer;


