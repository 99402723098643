import { configureStore } from '@reduxjs/toolkit'
import { pardotApi } from './slices/pardotApiSlice'
import snackReducer from "./reducers/snackReducer";
import configReducer from "./reducers/configReducer";
import targetReducer from "./reducers/targetReducer";
import registrationReducer from './slices/registrationSlice';
import leadFormReducer from './slices/leadFormSlice'

const store = configureStore({    
    reducer: {
        [pardotApi.reducerPath]: pardotApi.reducer,
        snackReducer,
        configReducer,
        targetReducer,
        registrationReducer,
        leadFormReducer,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false }).concat(pardotApi.middleware)
})

export default store;

