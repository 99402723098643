import React, { useState, useEffect } from "react";
import axios from "../../plugins/axios";
import {
    Box,
    Button,
    TextField,
    Typography,
    makeStyles,
    Divider as MuiDivider,
    Card,
    CardContent,
} from "@material-ui/core";
import styled from "styled-components";
import { spacing } from "@material-ui/system";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DocumentsTable from "./DocumentsTable";
import { useAuth } from "../../contexts/authContext";

const Divider = styled(MuiDivider)(spacing);

const useStyles = makeStyles((theme) => ({
    title: {
        marginRight: theme.spacing(6),
    },
    selectAccountContainer: {
        margin: "0 auto",
        padding: theme.spacing(2),
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: 400,
        },
    },
    documentsTableContainer: {
        margin: "0 auto",
        width: "75%",
        padding: theme.spacing(4),
    },
}));

const apiUrl = process.env.REACT_APP_WORKNEST_TOOLS_API_URL;

const Documents = () => {
    const classes = useStyles();
    const { user } = useAuth();
    const [open, setOpen] = useState(false);
    const [options, setOptions] = useState([]);
    const [input, setInput] = useState("");
    const [selectedAccount, setSelectedAccount] = useState();

    useEffect(() => {
        let active = true;

        if (!input || input.length < 3) {
            setOptions([]);
            return undefined;
        }

        (async () => {
            const clients = (await axios.get(`${apiUrl}/getclients/` + encodeURIComponent(input))).data;
            if (active) {
                setOptions(clients);
            }
        })();

        return () => {
            active = false;
        };
    }, [open, input]);

    const handleAccountChange = (e, v) => {
        setSelectedAccount({
            accountId: v && v.id,
            accountName: v && v.text,
        });
    };

    const selectAccountComponent = () => (
        <Card className={classes.selectAccountContainer}>
            <CardContent pb={1}>
                <Typography variant="h6" gutterBottom>
                    Select an account
                </Typography>
            </CardContent>
            <Box py={4} px={6}>
                <Autocomplete
                    freeSolo
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    getOptionSelected={(option, value) => option.value === value.value}
                    getOptionLabel={(option) => option.text}
                    onChange={handleAccountChange}
                    options={options}
                    onInputChange={(e, val) => setInput(val)}
                    renderInput={(params) => {
                        params.inputProps.autoComplete = "account-documents";
                        return <TextField {...params} fullWidth />;
                    }}
                />
            </Box>
        </Card>
    );

    if (!user)
        return null;

    return (
        <Box display="flex" flexDirection="column" my={3}>
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                py={3}
            >
                <Typography variant="h3" className={classes.title}>
                    Documents - {user.details.firstName} {user.details.lastName}
                </Typography>
                {selectedAccount && (
                    <Button variant="outlined" onClick={() => setSelectedAccount(null)}>
                        Select different account
                    </Button>
                )}
            </Box>

            <Divider mt={2} mb={6} />

            {!selectedAccount || !selectedAccount?.accountId ? (
                selectAccountComponent()
            ) : (
                <DocumentsTable account={selectedAccount} />
            )}
        </Box>
    );
};

export default Documents;
