import React from "react";
import {
    Typography,
    Box,
    makeStyles,
    FormControlLabel,
    FormControl,
    RadioGroup,
    Radio,
    Tooltip
} from "@material-ui/core";
import HelpIcon from "@material-ui/icons/Help";

const useStyles = makeStyles(() => ({
    radio: {
        '&.Mui-checked': {
            color: '#5c45c7'
        }
    },
    help: {
        color: '#5c45c7',
        marginLeft: '10px'
    }
}));

const RadioAnswer = ({ options, answer, setAnswer }) => {
    const classes = useStyles();    

    return (
        <FormControl component="fieldset">
            <RadioGroup value={answer} onChange={(e) => setAnswer(e.target.value)}>
                {options.map((o, index) =>
                    <Box display="flex" flexDirection="column" key={index}>
                        <FormControlLabel
                            value={o.key || o.main}
                            control={<Radio className={classes.radio} />}
                            label={
                                <Box display="flex">
                                    <Typography variant="subtitle2">{o.main}</Typography>
                                    {o.tooltip && <Tooltip title={o.tooltip}><HelpIcon fontSize="small" className={classes.help} /></Tooltip>}
                                </Box>                               
                            }
                        />
                        {o.sub && <Typography style={{ marginLeft: '30px', marginTop: '-10px' }}>{o.sub}</Typography>}
                    </Box>
                )}
            </RadioGroup>
        </FormControl>
    );
};

export default RadioAnswer;
