import React from "react";
import {
    Typography,
    Box,
    makeStyles,
    Grid,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
    tile: {
        padding: '10px',
        borderRadius: '5px',
        cursor: 'pointer',
        width: '100%',
        textAlign: 'left',
        height: '100%',
        "&:hover": {
            backgroundColor: '#cecece'
        },
        "&.selected": {
            color: 'white',
            backgroundColor: '#150950'
        }
    },
    title: {
        color: '#5c45c7'
    }
}));

const TileGroup = ({ options, answer, setAnswer }) => {
    const classes = useStyles();    

    return (
        <Grid container alignItems="stretch" spacing={2}>
            {options.map(o =>
                <Grid item xs={6} key={o.name}>
                    <Box
                        onClick={() => setAnswer(o.name)}
                        boxShadow={2}
                        display="flex"
                        className={`${classes.tile} ${answer === o.name ? "selected" : ""}`}
                        flexDirection="column"
                    >
                        <o.icon />
                        <Typography variant="subtitle2" className={answer === o.name ? classes.title: null}>{o.name}</Typography>
                        <Typography>{o.info}</Typography>
                    </Box>
                </Grid>
                
                
            )}
        </Grid>
    );
};

export default TileGroup;
