import * as React from "react";
import * as ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
import App from "./App";
import { AuthProvider } from "./contexts/authContext";
import { BrowserRouter as Router } from "react-router-dom";
import "react-sortable-tree/style.css";


if (process.env.NODE_ENV !== "development")
    console.log(`Build ${process.env.REACT_APP_BUILD_NUMBER} (${process.env.REACT_APP_COMMIT_HASH})`)

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <AuthProvider>
                <App />
            </AuthProvider>
        </Router>
    </Provider>,
    document.getElementById("root")
);