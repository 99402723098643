import React, {
    createContext,
    useCallback,
    useContext,
    useEffect,
    useState,
} from "react";
import * as msal from "@azure/msal-browser";
import {
    msalConfig,
    redirectTokenRequest,
    silentTokenRequest,
    loginRedirectRequest,
    PREFERRED_LOGIN_METHOD_KEY,
    LOGIN_METHODS,
    WORKNEST_TOKEN_KEY,
    REDIRECT_URL_KEY,
} from "../constants/authConfig";
import WorknestTokenError from "../errors/worknestTokenError";
import tokenService from "../services/tokenService";
import { setAuthHeader } from "../plugins/axios";
import jwtDecode from "jwt-decode";
import LoginLoader from "../components/LoginLoader";
import { useLocation } from "react-router-dom";
import { differenceInHours } from "date-fns";

const msalInstance = new msal.PublicClientApplication(msalConfig);

const MsalContext = createContext();

const setPreferredLoginMethod = (preferredLoginMethod) => localStorage.setItem(PREFERRED_LOGIN_METHOD_KEY, preferredLoginMethod);
const getPreferredLoginMethod = () => localStorage.getItem(PREFERRED_LOGIN_METHOD_KEY);
const setWorkNestToken = (workNestToken) => localStorage.setItem(WORKNEST_TOKEN_KEY, JSON.stringify(workNestToken));
const getWorkNestToken = () => JSON.parse(localStorage.getItem(WORKNEST_TOKEN_KEY));
const removeWorkNestToken = () => localStorage.removeItem(WORKNEST_TOKEN_KEY);

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [ignoreBrowserBehaviour, setIgnoreBrowserBehaviour] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (!localStorage.getItem(REDIRECT_URL_KEY) && location.pathname !== "/")
            localStorage.setItem(REDIRECT_URL_KEY, location.pathname);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initUserDetails = useCallback((workNestTokenResponse) => {
        const decoded = jwtDecode(workNestTokenResponse.token);
        console.log({ decoded });
        const details = {
            email: decoded.email,
            firstName: decoded.given_name,
            lastName: decoded.family_name,
            authMethod: decoded.authentication_method,
            userId: decoded.sub,
            roles: Array.isArray(decoded.roles) ? decoded.roles : [decoded.roles],
            tid: decoded.tid,
            tenant: decoded.tenant,
        };

        const userObject = {
            details,
            token: workNestTokenResponse.token,
            expiresOn: workNestTokenResponse.expires
        };
        console.log({ userObject });
        setUser(userObject);
        setAuthHeader(workNestTokenResponse.token);

        return details;
    }, []);

    const initialiseWorknestUserSession = useCallback(
        (workNestTokenResponse) => {
            console.log({ workNestTokenResponse });
            return new Promise(async (resolve, reject) => {
                try {
                    setWorkNestToken(workNestTokenResponse);
                    initUserDetails(workNestTokenResponse);
                } catch (e) {
                    reject(e);
                } finally {
                    setIsLoading(false);
                }
            });
        },
        [initUserDetails]
    );

    const handleMicrosoftTokenResponse = useCallback(async (azureToken) => {
        try {
            const worknestTokenResponse = await tokenService.microsoftWorkNestTokenExchange(azureToken.idToken);

            console.log({ worknestTokenResponse });
            initUserDetails(worknestTokenResponse);

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            throw error;
        }
    }, [initUserDetails]);

    const handleError = useCallback(
        async (error) => {
            setIsLoading(false);

            if (error instanceof WorknestTokenError) {
                // TODO: do something for worknest token error
                console.error(error.message);
                return;
            }

            if (error instanceof msal.InteractionRequiredAuthError) {
                try {
                    const redirectTokenResponse =
                        await msalInstance.acquireTokenRedirect(redirectTokenRequest);

                    await handleMicrosoftTokenResponse(redirectTokenResponse);
                } catch (error) {
                    console.error(error);
                }
            }
        },
        [handleMicrosoftTokenResponse]
    );

    const initMicrosoftAuth = useCallback(async () => {
        const token = await msalInstance.handleRedirectPromise();

        if (token) {
            await handleMicrosoftTokenResponse(token);
            return;
        }

        const accounts = msalInstance.getAllAccounts();
        if (!accounts.length) {
            setIsLoading(false);
        } else if (accounts.length === 1) {
            const account = accounts[0];
            const silentTokenResponse = await msalInstance.acquireTokenSilent({
                ...silentTokenRequest,
                account,
            });
            await handleMicrosoftTokenResponse(silentTokenResponse);
        } else {
            return await loginViaMicrosoft();
        }
    }, [handleMicrosoftTokenResponse]);

    const handleSessionInit = async () => {
        try {
            const workNestTokenFromStorage = getWorkNestToken();

            console.log({ workNestTokenFromStorage });
            if (workNestTokenFromStorage) {
                const decoded = jwtDecode(workNestTokenFromStorage.token);
                const hoursToExpiry = differenceInHours(
                    new Date(decoded.exp * 1000),
                    new Date()
                );
                if (hoursToExpiry >= 3) {
                    await initialiseWorknestUserSession(workNestTokenFromStorage);
                    return;
                }
            }

            const preferredLogin = getPreferredLoginMethod();

            if (preferredLogin === LOGIN_METHODS.microsoft) {
                await initMicrosoftAuth();
            } else {
                setIsLoading(false);
            }
        } catch (error) {
            handleError(error);
        }
    };

    useEffect(() => {
        handleSessionInit();
    }, [handleError, initMicrosoftAuth, initialiseWorknestUserSession]);


    const onGoogleLoginSuccess = async (googleTokenResponse) => {
        try {
            setPreferredLoginMethod(LOGIN_METHODS.google);
            const worknestTokenResponse = await tokenService.googleWorkNestTokenExchange(googleTokenResponse);

            console.log({ worknestTokenResponse });
            await initialiseWorknestUserSession(worknestTokenResponse);
        } catch (error) {
            handleError(error);
        }
    };

    const loginViaMicrosoft = async () => {
        setPreferredLoginMethod(LOGIN_METHODS.microsoft);
        await msalInstance.loginRedirect(loginRedirectRequest);
    };

    const logout = async () => {
        removeWorkNestToken();
        const authMethod = user?.details.authMethod;
        if (authMethod === LOGIN_METHODS.microsoft) {
            return await msalInstance.logoutRedirect();
        } else if (authMethod === LOGIN_METHODS.google) {
            setUser(null);
            // eslint-disable-next-line no-unused-expressions
            window.google?.accounts.id.disableAutoSelect();
        }
    }

    const hasRole = (role) => {
        return user?.details.roles && user.details.roles.includes(`tools:${role.toLowerCase()}`);
    };

    if (isLoading)
        return <LoginLoader />;

    const contextValue = {
        loginViaMicrosoft,
        isLoading,
        user,
        logout,
        hasRole,
        ignoreBrowserBehaviour,
        setIgnoreBrowserBehaviour,
        onGoogleLoginSuccess,
        handleSessionInit,
    };

    return (
        <MsalContext.Provider value={contextValue}>
            {children}
        </MsalContext.Provider>
    );
};

const useAuth = () => useContext(MsalContext);
export { AuthProvider, useAuth, MsalContext };

