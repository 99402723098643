/* eslint-disable no-unused-expressions */
import React, { useEffect, useRef, useState } from 'react';
import {
    Grid,
    Button,
    Typography,
    Icon,
    makeStyles,
} from '@material-ui/core';
import receptionImg from '../images/reception.jpg';
import worknestLogo from '../images/WorkNest_Purple_Positive_RGB.svg';
import LandingLayout from '../layouts/LandingLayout';
import { useAuth } from "../contexts/authContext";
import useInterval from "../hooks/useInterval";
const msLogoUrl = `${process.env.PUBLIC_URL}/images/ms-logo.svg`;
const googleLogoUrl = `${process.env.PUBLIC_URL}/images/google-logo.svg`;


const useStyles = makeStyles((theme) => ({
    microsoftLoginButton: {
        backgroundColor: "#2f2f2f",
        borderRadius: 0,
        width: "100%",
        maxWidth: "300px",
        fontWeight: 600,
        fontSize: "15px",
        fontFamily: "Segoe UI, Arial, sans-serif",
        color: "#fff",
        "&:hover": {
            backgroundColor: "#2f2f2f",
        },
        marginLeft: "auto",
        marginRight: "auto",
        lineHeight: "normal",
        height: "40px"
    },
    googleLoginButton: {
        backgroundColor: "white",
        borderRadius: 4,
        variant: "outlined",
        width: "100%",
        fontWeight: 600,
        fontSize: "15px",
        fontFamily: "Segoe UI, Arial, sans-serif",
        color: "black",
        "&:hover": {
            backgroundColor: "white",
        },
        lineHeight: "normal",
        boxShadow: "none",
        border: "1px solid #ccc",
        height: "40px"
    },
    buttonIcon: {
        height: "100%",
        display: "flex",
        alignItems: "center",
    },
}));

const LandingPage = () => {

    const { loginViaMicrosoft, onGoogleLoginSuccess } = useAuth();

    const classes = useStyles();

    const MicrosoftLoginButton = () => {     

        const loginMicrosoft = async () => {
            try {
                localStorage.setItem('redirect', window.location.pathname);
                console.log('login gettoken');
                await loginViaMicrosoft();
            } catch (error) {
                console.error(error);
            }
        };
            
        return (
            <Button
                variant="contained"
                className={classes.microsoftLoginButton}
                classes={{ startIcon: classes.startIcon }}
                onClick={loginMicrosoft}
                startIcon={
                    <Icon classes={{ root: classes.buttonIcon }}>
                        <img src={msLogoUrl} alt=""/>
                    </Icon>
                }
            >
                Sign in with Microsoft
            </Button>
        );
    };

    const GoogleLoginButton = () => {

        const createFakeGoogleWrapper = () => {
            const googleLoginWrapper = document.createElement("div");
            googleLoginWrapper.style.display = "none";
            //googleLoginWrapper.classList.add("custom-google-button");

            document.body.appendChild(googleLoginWrapper);

            window.google?.accounts.id.renderButton(googleLoginWrapper, {
                type: "icon",
                width: "200",
            });

            const googleLoginWrapperButton = googleLoginWrapper.querySelector("div[role=button]");

            return {
                click: () => {
                    googleLoginWrapperButton.click();
                },
            };
        }


        const googleButtonWrapper = createFakeGoogleWrapper();

        const loginGoogle = async () => {
            try {
                localStorage.setItem('redirect', window.location.pathname);
                googleButtonWrapper.click();
            } catch (error) {
                console.error(error);
            }
        }; 
       
        const [isGoogleLoading, setIsGoogleLoading] = useState(true);

        const callback = () => {
            if (typeof window !== "undefined" && window.google)
                setIsGoogleLoading(false);
        }

        useInterval(callback, isGoogleLoading ? 100 : null);

        useEffect(() => {
            window.google?.accounts.id.initialize({
                client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
                auto_select: true,
                callback: (credentialResponse) => {
                    if (!credentialResponse.clientId || !credentialResponse.credential)
                        return;

                    onGoogleLoginSuccess(credentialResponse.credential);
                },
            });
           
            return () => {
                window.google?.accounts.id.cancel();
            };
        }, [isGoogleLoading]);

        return (
         
            
            <Button
                variant="contained"
                className={classes.googleLoginButton}
                classes={{ startIcon: classes.startIcon }}
                onClick={loginGoogle}
                startIcon={
                    <Icon classes={{ root: classes.buttonIcon }}>
                        <img src={googleLogoUrl} alt="" />
                    </Icon>
                }
            >
                Sign in with Google
                </Button>
         
        );
    };

    return (
        <LandingLayout>
            <Grid
                container
                style={{ minheight: '100vh' }}
                justifyContent='space-between'>
                <Grid item xs={12} sm={6}>
                    <img
                        src={receptionImg}
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                        }}
                        alt='Legal'
                    />
                </Grid>
                <Grid
                    container
                    item
                    xs={12}
                    sm={6}
                    alignItems='center'
                    direction='column'
                    justifyContent='space-between'
                    style={{ padding: 10 }}>
                    <div />
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            maxWidth: 300,
                            minWidth: 200,
                            gap: 10,
                        }}>
                        <Grid
                            container
                            justifyContent='center'
                            style={{ padding: 20 }}>
                            <img
                                src={worknestLogo}
                                alt='logo'
                                width={300}
                            />
                        </Grid>

                        <MicrosoftLoginButton />
                        <GoogleLoginButton />
                    </div>
                    <Typography variant='h1'>WORKNEST TOOLS</Typography>
                    <div />
                </Grid>
            </Grid>
        </LandingLayout>
    );
};

export default LandingPage;
