import React from "react";
import {
    makeStyles,
    Button,
    ButtonGroup,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
    button: {
        backgroundColor: '#5c45c7',
        color: 'white',
        '&:hover': {
            backgroundColor: '#150950'
        }
    },
}));

const ButtonAnswer = ({ options, answer, setAnswer }) => {
    const classes = useStyles();    

    return (
        <ButtonGroup value={[answer]} onChange={setAnswer} fullWidth>
            {options.map(o =>
                <Button
                className={answer === o ?  classes.button : null}
                variant={answer === o ? "contained" : ""}
                key={o} onClick={() => setAnswer(o)}>
                {o}
            </Button>
            )}
        </ButtonGroup>      
    );
};

export default ButtonAnswer;
