export const baseScopes = `openid profile offline_access`;

export const silentTokenRequest = {
    scopes: [],
    forceRefresh: true,
};

export const redirectTokenRequest = {
    scopes: [],
};

export const loginRedirectRequest = {
    scopes: [baseScopes]
};


export const msalConfig = {
    cache: {
        cacheLocation: "localStorage",
    },
    auth: {
        clientId: '9dd0fc26-a702-440a-aead-36690ac4b63d',
        redirectUri: window.location.origin,
        postLogoutRedirectUri: window.location.origin,
        navigateToLoginRequestUrl: false,
        authority: "https://login.microsoftonline.com/common",
        knownAuthorities: ["https://login.microsoftonline.com/common"],
    },
};


export const REDIRECT_URL_KEY = "redirect_url";
export const PREFERRED_LOGIN_METHOD_KEY = "preferred_login_method";
export const WORKNEST_TOKEN_KEY = "worknest_token";

export const LOGIN_METHODS = {
    microsoft: "group-microsoft",
    google: "google",
};

