import React from "react";
import styled, { withTheme } from "styled-components";
import worknestLogo from '../images/worknest-small.svg'
import { useAuth } from "../contexts/authContext";
import logoutIcon from '../images/logout-line.svg'

import {
    Grid,
    AppBar as MuiAppBar,
    Toolbar,
    Button,
    makeStyles
} from "@material-ui/core";

import { Link } from "react-router-dom";

const AppBar = styled(MuiAppBar)`
  color: ${props => props.theme.header.color};
  box-shadow: ${props => props.theme.shadows[1]};
`;

const useStyles = makeStyles(() => ({
    appBar: {
        backgroundColor: '#150850'
    },
    gridItem: {
        display: 'flex'
    },
    logo: {
        height: '50px',
        width: '50px'
    },
    title: {
        color: 'white', 
        textDecoration: 'none', 
        fontSize: '1.25rem', 
        fontWeight: '500', 
        marginLeft: '10px'
    },
    button: {
        borderRadius: '100%', 
        width: '35px', 
        height: '35px', 
        minWidth: '0px', 
        background: '#eee'
    },
    buttonIcon: {
        height: '15px', 
        width: '15px'
    }
}))

const Header = () => {        
    const classes = useStyles()
    
    const { logout } = useAuth();

    return (
        <React.Fragment>
            <AppBar className={classes.appBar} position="sticky" elevation={0}>
                <Toolbar>
                    <Grid container alignItems="center">   
                        <Grid container className={classes.gridItem} alignItems='center' item xs={11}>
                            <img alt='logo' src={worknestLogo} className={classes.logo} />                     
                            <Link className={classes.title} to="/">WorkNest Tools</Link>
                        </Grid>
                        <Grid container className={classes.gridItem} justifyContent='flex-end' item xs={1}>
                            <Button className={classes.button} variant="contained" onClick={logout}>
                                <img className={classes.buttonIcon} alt='Logout' src={logoutIcon} />
                            </Button>
                        </Grid>                       
                    </Grid>
                </Toolbar>
            </AppBar>            
        </React.Fragment>
    );
}

export default withTheme(Header);
