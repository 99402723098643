import React, { useState, useEffect, useMemo, useCallback } from 'react';
import axios from '../../plugins/axios';
import { Alert } from "@material-ui/lab";
import SurveyQuestion from "./SurveyQuestion";
import { useAuth } from "../../contexts/authContext";

import {
    Button,
} from "@material-ui/core";

const apiUrl = process.env.REACT_APP_WORKNEST_TOOLS_API_URL;

const CompleteSurvey = ({ match }) => {

    const { user } = useAuth();
    const [loading, setLoading] = useState(false);
    const { identifier, surveyKey } = match.params;
    const [surveyInfo, setSurveyInfo] = useState();
    const [complete, setComplete] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [hidden, setHidden] = useState(false);
    const [message, setMessage] = useState();

    const validEmail = v => {
        var valid = /^([\w-.']+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1, 3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,9}|[0-9]{1,3})(\]?)$/.test(v);
        return valid;
    }

    const showQuestion = useCallback(q => {
        if (q.dependsOnQuestionId == null) {
            return true;
        }

        const parent = surveyInfo.questions.find(x => x.questionId === q.dependsOnQuestionId);

        if (parent && ((parent.answer && q.dependsOnQuestionResponse === "" && showQuestion(parent)) || (parent.answer === q.dependsOnQuestionResponse && showQuestion(parent)))) {            
            return true;
        }

        return false;
    }, [surveyInfo]);

    const findAccountId = useCallback(q => {

        let question = q;
        let accountId = null;

        while (accountId === null && question) {

            if (question.questionType === 4)
                accountId = question.answer;

            let dependsOnQuestionId = question.dependsOnQuestionId;

            question = surveyInfo.questions.find(x => x.questionId === dependsOnQuestionId);
        }

        return accountId;

    }, [surveyInfo])

    const questionIndent = useCallback(q => {
        if (q.dependsOnQuestionId == null)
            return 0;

        const parent = surveyInfo.questions.find(x => x.questionId === q.dependsOnQuestionId);

        return questionIndent(parent) + 1;
    }, [surveyInfo]);

    const valid = useMemo(() => {
        if (!surveyInfo)
            return false;
        var errors = surveyInfo.questions.filter(q => showQuestion(q) && q.error);
        return errors.length === 0;
    }, [surveyInfo, showQuestion]);


    const visibleQuestions = useMemo(() => {
        if (!surveyInfo)
            return [];
        return surveyInfo.questions.filter(q => showQuestion(q));
    }, [surveyInfo, showQuestion]);

    const questionError = useCallback(q => {
        if (q.required && !q.answer)
            return "Required";

        if (q.questionType === 3 && q.answer && !validEmail(q.answer))
            return "Email must be valid";

        return null;
    }, []);

    const updateAnswer = (questionId, value, dateValue) => {        

        setSurveyInfo({
            ...surveyInfo,
            questions: surveyInfo.questions.map(q =>
                q.questionId === questionId
                    ? {
                        ...q,
                        answer: value,
                        dateAnswer: dateValue,
                        error: questionError({ ...q, answer: value })
                    }
                    : q)
        });
    }

    const setSurvey = useCallback(data => {
        if (data) {
            if (data === "hidden")
                setHidden(true);
            else {
                setSurveyInfo({
                    ...data,
                    questions: data.questions.map(q => ({
                        ...q,
                        error: questionError(q)
                    }))
                });
            }
        }
    }, [questionError]);

    useEffect(() => {
        if (user) {
            if (identifier || surveyKey) {
                setLoading(true);

                if (identifier) {
                    axios
                        .get(`${apiUrl}/getsurvey/${identifier}`)
                        .then(response => setSurvey(response.data))
                        .finally(() => setLoading(false));
                }

                if (surveyKey) {
                    axios
                        .get(`${apiUrl}/getsurveyfromkey/${surveyKey}`)
                        .then(response => setSurvey(response.data))
                        .finally(() => setLoading(false));
                }
            }
        }

    }, [user, identifier, surveyKey, setSurvey]);

    const submit = async () => {
        setSubmitting(true);

        const obj = {
            identifier: identifier,
            key: surveyInfo.key,
            questions: visibleQuestions,
            accountName: surveyInfo.accountName,
            userName: surveyInfo.userName
        };

        try {
            //let response = await axios.post(`${apiUrl}/submitsurvey`, obj);

            var formData = new FormData();
            
            for (let question of obj.questions) {                
                if (question.questionType === 11 && question.answer) {
                    for (let file of question.answer) {                        
                        formData.append("files", file);
                    }
                    question.answer = null;
                }
            }           

            formData.append("surveyJson", JSON.stringify(obj));

            let response = await axios.post(
                `${apiUrl}/submitsurvey`,
                formData,
                {
                    headers: {
                        'Accept': 'application/json',
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            setComplete(true);
            setMessage(response.data);
        }
        catch {
            setSubmitting(false);
        }
    }

    return (
        <div style={{ maxWidth: '1000px', margin: '0 auto', backgroundColor: 'white', padding: 0 }}>
            {
                hidden &&
                <div style={{ padding: '20px' }}>
                    <h1>Form is temporarily unavailable for updating.</h1>
                    <h1>Please try again in a few minutes.</h1>
                </div>
            }
            {
                ((!identifier && !surveyKey) || (!loading && !surveyInfo && !hidden)) &&
                <div style={{ padding: '20px' }}>
                    <h1>Nothing to see here!</h1>
                    <p>This could be for one of many reasons.  Most likely that the survey has been removed by Admin, since it was added in error.</p>
                </div>
            }
            {
                loading && <div style={{ padding: '20px' }}><h1>Loading ...</h1></div>
            }
            {
                surveyInfo &&
                <div>
                    {
                        surveyInfo.completedOn &&
                        <Alert severity="error">This form has already been completed.  Completing again will override existing data.</Alert>
                    }
                    <div style={{ backgroundColor: '#374b75', padding: '20px', color: 'white' }}>
                        <div style={{ fontSize: '2em' }}>{surveyInfo.description}</div>
                        {surveyInfo.accountName && <div>Client: {surveyInfo.accountName}</div>}
                        {surveyInfo.userName && <div>{surveyInfo.userName}</div>}
                    </div>
                    {
                        complete &&
                            <div style={{ padding: '40px' }}>
                            <h1 style={{ textAlign: 'center' }}>Survey Submitted</h1>
                            {message && <p style={{ textAlign: 'center', paddingTop: '20px' }}>{message}</p>}
                        </div>
                    }
                    {
                        !complete &&
                        <div style={{ padding: '10px' }}>
                            {
                                visibleQuestions.map(question =>
                                    <div key={question.questionId} style={{ paddingLeft: `${questionIndent(question) * 20}px` }} >
                                        <SurveyQuestion question={question} updateAnswer={updateAnswer} findAccountId={findAccountId}></SurveyQuestion>
                                    </div>
                                )
                            }
                            {
                                valid &&
                                <Button onClick={submit} disabled={submitting} variant="contained">{submitting ? "Submitting" : "Submit"}</Button>
                            }
                        </div>
                    }
                </div>
            }
        </div>
    )
};

export default CompleteSurvey;
