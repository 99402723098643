import React, { useState, useEffect, useCallback, useMemo } from 'react';
import axios from '../plugins/axios';
import styled from "styled-components";
import { spacing } from "@material-ui/system";
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment'
import { Link } from "react-router-dom";
import { useAuth } from "../contexts/authContext";

import {
    Box,
    Table,
    TableCell,
    TableBody,
    TableHead,
    TableRow,
    Card as MuiCard,
    Divider as MuiDivider,
    Paper as MuiPaper,
    Button
} from "@material-ui/core";

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const Paper = styled(MuiPaper)(spacing);

const useStyles = makeStyles(() => ({
    infoBox: {
        "&.selected": {
            border: '2px solid #12054d'
        },
        border: '2px solid transparent',
        height: '80px',
        width: '150px',
        padding: '12px',
        background: '#ededed',
        margin: '15px',
        color: 'white'
    }
}));

const apiUrl = process.env.REACT_APP_WORKNEST_TOOLS_API_URL;

const Opportunities = () => {

    const { user } = useAuth();
    const classes = useStyles();

    const [opportunities, setOpportunities] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selected, setSelected] = useState("Submitted Cross Sales");

    const getOpportunities = useCallback(async () => {

        if (!user)
            return;

        try {
            setLoading(true);
            var response = await axios.get(`${apiUrl}/myopportunities`);
            setOpportunities(response.data);
        }
        finally {
            setLoading(false);
        }
    }, [user]);


    useEffect(() => { getOpportunities(); }, [user, getOpportunities]);

    const cards = useMemo(() => {

        let cards = [
            { color: '#a38cc6', count: opportunities.length, name: "Submitted Cross Sales", stages: [] },
            { color: '#6ab8f7', count: 0, name: "IN PROGRESS", stages: ["Working", "Proposal Sent", "Assigned"] },
            { color: '#21dfcb', count: 0, name: "Sales WON", stages: ["Closed Won"] },
            { color: '#fe85be', count: 0, name: "Sales LOST", stages: ["Closed Lost"] }
        ];

        for (const opp of opportunities) {
            const card = cards.find(c => c.stages.includes(opp.stageName));
            if (card) {
                card.count++;
            }
        }

        return cards;

    }, [opportunities]);

    const filteredOpportunities = useMemo(() => {
        let card = cards.find(x => x.name === selected);
        if (card === cards[0])
            return opportunities;

        return opportunities.filter(o => card.stages.includes(o.stageName));
    }, [opportunities, selected, cards]);

    if (!user)
        return null;

    return (
        <Box display="flex" flexDirection="column" style={{ paddingTop: '10px' }}>
            <Box textAlign="right"><Button variant="contained" component={Link} to="/complete-form/Lead_Form" color="primary">Create New Lead</Button></Box>

            <Box display="flex" flexDirection="row" justifyContent="space-between" style={{ padding: '20px' }}>
                <Box></Box>
                {cards.map(c =>
                    <Box
                        flexDirection="column"
                        style={{ background: c.color }}
                        display="flex"
                        key={c.name}
                        onClick={() => { setSelected(c.name); }}
                        className={`${classes.infoBox} ${(c.name === selected ? "selected" : "")}`}
                    >
                        <Box style={{ fontSize: '1.8em' }}>{c.count}</Box>
                        <Box style={{ fontSize: '0.8em' }}>{c.name}</Box>
                    </Box>)}
                <Box></Box>
            </Box>

            <Divider my={2} />
            <Card m={6}>
                <Paper>
                    {loading && <h2 style={{ margin: '10px' }}>Loading ...</h2>}
                    {!loading && filteredOpportunities && filteredOpportunities.length === 0 && <h2 style={{ margin: '10px' }}>No entries</h2>}
                    {
                        !loading && filteredOpportunities && filteredOpportunities.length > 0 &&
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Account</TableCell>
                                    <TableCell align="center">Date Submitted</TableCell>
                                    <TableCell align="center">Type</TableCell>
                                    <TableCell align="center">Service</TableCell>
                                    <TableCell align="center">Owner</TableCell>
                                    <TableCell align="center">Stage</TableCell>
                                    <TableCell align="center">Closed Date</TableCell>
                                    <TableCell align="center">Estimated Commission</TableCell>
                                    <TableCell align="center">Final Commission</TableCell>
                                    <TableCell align="center">Month of Payment</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    opportunities.map(o =>
                                        <TableRow key={o.id}>
                                            <TableCell>{o.accountName}</TableCell>
                                            <TableCell align="center">{moment(o.createdDate).format("DD/MM/YYYY")}</TableCell>
                                            <TableCell align="center">{o.opportunityType}</TableCell>
                                            <TableCell align="center">{o.requestedService}</TableCell>
                                            <TableCell align="center">{o.owner}</TableCell>
                                            <TableCell align="center">{o.stageName}</TableCell>
                                            <TableCell align="center">{o.closeDate && moment(o.closeDate).format("DD/MM/YYYY")}</TableCell>
                                            <TableCell align="center">{o.commission}</TableCell>
                                            <TableCell align="center">{o.finalCommissionAmount}</TableCell>
                                            <TableCell align="center">{o.monthOfPayment}</TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    }
                </Paper>
            </Card>
        </Box>
    )
};

export default Opportunities;
