export const SET_SERVER_VALUES = 'SET_SERVER_VALUES';
export const SET_TEMPLATE_INFO = 'SET_TEMPLATE_INFO';
export const SET_TEMPLATE = 'SET_TEMPLATE';
export const SET_SECTION = 'SET_SECTION';
export const SET_DOCUMENT_TEMPLATE_ID = 'SET_DOCUMENT_TEMPLATE_ID';

export const SET_SNACK = 'SET_SNACK';
export const REMOVE_SNACK = 'REMOVE_SNACK';

//targets
export const SET_TARGET_PILLAR_ID = 'SET_TARGET_PILLAR_ID';
export const SET_TARGET_AREA_ID = 'SET_TARGET_AREA_ID';
export const SET_TARGETS = 'SET_TARGETS';
export const SET_AREAS = 'SET_AREAS';
export const SET_PILLARS = 'SET_PILLARS';
export const SET_LOADING = 'SET_LOADING';
export const SET_SELECTED_DATE = 'SET_SELECTED_DATE';
export const SET_TARGET_EDITING = 'SET_TARGET_EDITING';
export const SET_TARGET_VIEW = 'SET_TARGET_VIEW';
export const SET_TARGET_VALUE = 'SET_TARGET_VALUE';

