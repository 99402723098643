import axios, { setAuthHeader } from "../plugins/axios";

const microsoftWorkNestTokenExchange = async (authToken) => {
    setAuthHeader(authToken);

    let response = await axios.get(`${process.env.REACT_APP_AUTH_API_URL}/token/groupmicrosoft`);
    return response.data;
};

const googleWorkNestTokenExchange = async (authToken) => {

    setAuthHeader(authToken);

    let response = await axios.get(`${process.env.REACT_APP_AUTH_API_URL}/token/groupgoogle`);
    return response.data;
};

const tokenService = {
    microsoftWorkNestTokenExchange,
    googleWorkNestTokenExchange,
};

export default tokenService;
