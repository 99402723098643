import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionCreators } from '../../store/reducers/configReducer';
import TemplateEditor from './TemplateEditor';
import SectionEditor from './SectionEditor';
import { format } from 'date-fns'
import TabPanel from "../TabPanel";
import { useHistory } from 'react-router-dom';
import { useAuth } from "../../contexts/authContext";

import {
    Box,
    Table,
    TableBody,
    TableRow,
    TableCell,
    IconButton,
    Tab,
    Tabs,
    TextField
} from "@material-ui/core";

import {
    Edit as EditIcon,
    Add as AddIcon,
    NoteAdd as NoteAddIcon
} from "@material-ui/icons"


const Templates = ({ getTemplateInfo, templates, sections, setDocumentTemplateId }) => {

    const { user, hasRole } = useAuth();
    const [editingTemplate, setEditingTemplate] = useState();
    const [editingSection, setEditingSection] = useState();
    const [currentTab, setCurrentTab] = useState(0);
    const [sectionFilter, setSectionFilter] = useState("");

    const history = useHistory();

    const isAdmin = useMemo(() => hasRole("TemplatesAdmin"), [hasRole]);

    useEffect(() => {
        if (!user)
            return;

        getTemplateInfo();

    }, [user, getTemplateInfo]);    

    const filteredSections = useMemo(() => {

        let filter = sectionFilter.trim().toLowerCase();

        if (filter === "")
            return sections;

        return sections.filter(s => s.key.toLowerCase().includes(filter));

    }, [sections, sectionFilter]);

    const handleTabChange = (e, newValue) => {
        setEditingTemplate(null);
        setEditingSection(null);
        setCurrentTab(newValue);
    }

    const addTemplate = () => {
        var newTemplate = {};
        setEditingTemplate(newTemplate);
    }

    const addSection = () => {
        var newSection = {};
        setEditingSection(newSection);
    }

    const createDocument = id => {
        setDocumentTemplateId(id);
        history.push("/template-document");
    }

    if (!user)
        return null;

    return (
        <Box display="flex" flexDirection="column" style={{ paddingTop: '10px' }}>
            <Tabs value={currentTab} onChange={handleTabChange} style={{marginBottom:'10px'}}>
                <Tab label="Templates" />
                {isAdmin && <Tab label="Sections" />}
            </Tabs>
            <TabPanel value={currentTab} index={0}>
                {
                    !editingTemplate &&
                    <React.Fragment>
                            {isAdmin && <IconButton onClick={addTemplate}><AddIcon /></IconButton>}
                            <Table size="small">
                                <TableBody>
                                    {
                                        templates.map(t =>
                                            <TableRow key={t.templateId}>
                                                <TableCell>{t.name}</TableCell>
                                                <TableCell>{format(new Date(t.lastModifiedOn), 'dd/MM/yyyy HH:mm')}</TableCell>
                                                {isAdmin && <TableCell><IconButton onClick={() => setEditingTemplate(t)}><EditIcon /></IconButton></TableCell>}
                                                <TableCell><IconButton onClick={() => createDocument(t.templateId)}><NoteAddIcon /></IconButton></TableCell>
                                            </TableRow>
                                        )
                                    }
                                </TableBody>
                            </Table>
                    </React.Fragment>                    
                }
                {
                    editingTemplate &&
                    <TemplateEditor template={editingTemplate} onSave={() => setEditingTemplate(null)} />
                }
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
                {
                    !editingSection &&
                    <>
                        <Box display="flex" alignItems="center">
                            <IconButton onClick={addSection}><AddIcon /></IconButton>
                            <TextField
                                value={sectionFilter}
                                onChange={e => setSectionFilter(e.target.value)}
                                variant="outlined"
                                size="small"
                                label="filter"
                            />
                        </Box>                        
                        <Table size="small">
                            <TableBody>
                                {
                                    filteredSections.map(s =>
                                        <TableRow key={s.sectionId}>
                                            <TableCell>{s.key}</TableCell>
                                            <TableCell>{s.question}</TableCell>
                                            <TableCell>{format(new Date(s.lastModifiedOn), 'dd/MM/yyyy HH:mm')}</TableCell>
                                            <TableCell><IconButton onClick={() => setEditingSection(s)}><EditIcon /></IconButton></TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table>
                    </>
                }
                {
                    editingSection &&
                    <SectionEditor section={editingSection} onSave={() => setEditingSection(null)} />
                }
            </TabPanel>
            
        </Box>
    )
};

const mapStateToProps = state => ({
    templates: Object.values(state.configReducer.templates).sort((a, b) => (a.name === b.name) ? 0 : (a.name < b.name) ? -1 : 1),
    sections: Object.values(state.configReducer.sections).sort((a, b) => (a.key === b.key) ? 0 : (a.key < b.key) ? -1 : 1)
});
const mapDispatchToProps = dispatch => bindActionCreators(actionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Templates);
